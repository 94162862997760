import React, { useRef, useState } from "react";

import emailjs from "@emailjs/browser";
import toast from "react-hot-toast";

const ContactForm = () => {
  const form = useRef();
  const [error, setError] = useState(null);

  const [name, setName] = useState();
  const [email, setemail] = useState();
  const [phone, setphone] = useState();
  const [message, setmessage] = useState();

  const sendEmail = (e) => {
    e.preventDefault();

    if(!name || !email || !phone || !message) {
        toast.error("Please Fill All Fields");
    } else {
        emailjs
        .sendForm(
          "service_umguxi7",
          "template_xkhubmf",
          form.current,
          "RY6g5sa_TIqx1Pg3F"
        )
        .then(
          toast.success("Successfully Submit"),
            setName(''),
            setemail(""),
            setphone(''),
            setmessage(''),
        );
    }

  
   
  };

  return (
    <form
      ref={form}
      onSubmit={sendEmail}
      className="contact-form-wrapper"
      data-aos="fade-up"
      data-aos-duration="1200"
    >
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="name"
              id="name"
              required
              placeholder="Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input
              required
              className="form-control"
              type="email"
              name="email"
              id="email"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setemail(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input
              required
              className="form-control"
              type="text"
              name="phone"
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => setphone(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group mb-0">
            <textarea
              required
              name="message"
              rows="5"
              placeholder="Write your message here"
              value={message}
              onChange={(e) => setmessage(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className="col-md-12 text-center">
          <div className="form-group mb-0">
            <button
         
              onClick={sendEmail}
              className="btn btn-theme btn-block bb-shadow"
              type="submit"
            >
              Send Message
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
