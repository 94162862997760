import React from "react";
import { NavLink } from "react-router-dom";

const MainMenu = () => {
    return (
        <nav>
            <ul className="main-menu">
                <li className="">
                    <NavLink
                        activeStyle={{ borderBottom: "solid 2px #fff" }}
                        className="main-menu-link"
                        exact
                        to="/"
                    >
                        Home
                    </NavLink>
                </li>

                <li>
                    <span 
                    style={{cursor:'pointer'}}
                    className="main-menu-link">Products</span>
                    <ul className="sub-menu">
                        <li>
                            <NavLink
                                activeStyle={{
                                    borderBottom: "solid 2px #588ac0",
                                }}
                                className="sub-menu-link"
                                to={"/Injectable"}
                            >
                                Injectable
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={"/Oral"}
                                activeStyle={{
                                    borderBottom: "solid 2px #588ac0",
                                }}
                            >
                                Oral
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/service-details/1"
                                }
                            >
                                Premium Range Products
                            </NavLink>
                        </li> */}
                        {/* <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/service-details/1"
                                }
                            >
                                Serms
                            </NavLink>
                        </li> */}
                    </ul>
                </li>
                <li>
                    <NavLink
                        activeStyle={{ borderBottom: "solid 2px #fff" }}
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/Company-Profile"}
                    >
                        Company Profile
                    </NavLink>
                </li>

                <li>
                    <NavLink
                        activeStyle={{
                            borderBottom: "solid 2px #fff",
                            paddingBottom: "1em",
                        }}
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/check-authenticity"}
                    >
check-authenticity                    </NavLink>
                </li>
                <li>
                    <NavLink
                        activeStyle={{ borderBottom: "solid 2px #fff" }}
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/Contact"}
                    >
                        Contact
                    </NavLink>
                </li>
                <li>
                    <NavLink
                    style={{fontSize:' 20px'}}
                        activeStyle={{ borderBottom: "solid 2px #fff" }}
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/Brimstone"}
                    >
               BRIMSTONE LABS
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default MainMenu;
