import React from "react";
import Layout from "../../layouts/index.jsx";
import Header from "../../layouts/header";
import Footer from "../../layouts/footer";

import ScrollToTop from "../../components/scroll-to-top";
const Products2 = () => {
  return (
    <React.Fragment>
      <Layout>
        <div className="wrapper">
          <Header />
          <div>
            <h1 data-aos="fade-up" className="head-pro">
              Oral
            </h1>
            <div className="head-pro1">
              <div className="head-pro2">
                <div className="head-flexi">
                  <div className="head-pro3">
                    <div>
                      <span className="head-pros">Anadrol-50</span>
                    </div>
                    <div className="pro-imggd">
                      <img
                        src={require("../../assets/TABLETNEW/ANADROL.jpg")}
                        alt=""
                      />
                    </div>
                    <div>
                      <span className="head-pros">Each capsule contains:</span>
                      <div className="head-di2">
                        <div className="head-di1">
                          <span>Oxymetholone 50 mg</span>
                        </div>
                        <div className="head-di1">
                          <span>1 Bottle of 100 Capsules </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="head-pro3">
                    <div>
                      <span className="head-pros">CLENBUTEROL</span>
                    </div>
                    <div className="pro-imggd">
                      <img
                        src={require("../../assets/TABLETNEW/CLEN.jpg")}
                        alt=""
                      />
                    </div>
                    <div>
                      <span className="head-pros">Each capsule contains:</span>
                      <div className="head-di2">
                        <div className="head-di1">
                          <span>Clenbuterol HCL 40 mcg</span>
                        </div>
                        <div className="head-di1">
                          <span>1 Bottle of 100 Capsules </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="head-pro3">
                    <div>
                      <span className="head-pros">anavar</span>
                    </div>
                    <div className="pro-imggd">
                      <img
                        src={require("../../assets/TABLETNEW/ANAVAR.jpg")}
                        alt=""
                      />
                    </div>
                    <div>
                      <span className="head-pros">Each capsule contains:</span>
                      <div className="head-di2">
                        <div className="head-di1">
                          <span>Oxandrolone 10 mg</span>
                        </div>
                        <div className="head-di1">
                          <span>1 Bottle of 100 Capsules </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="head-pro3">
                    <div>
                      <span className="head-pros">cytomel</span>
                    </div>
                    <div className="pro-imggd">
                      <img
                        src={require("../../assets/TABLETNEW/CYTOMEL.jpg")}
                        alt=""
                      />
                    </div>
                    <div>
                      <span className="head-pros">Each capsule contains:</span>
                      <div className="head-di2">
                        <div className="head-di1">
                          <span>Liothyronine Sodium 25 mcg</span>
                        </div>
                        <div className="head-di1">
                          <span>1 Bottle of 100 Capsules </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="head-pro3">
                    <div>
                      <span className="head-pros">Dianabol</span>
                    </div>
                    <div className="pro-imggd">
                      <img
                        src={require("../../assets/TABLETNEW/DIANABOL.jpg")}
                        alt=""
                      />
                    </div>
                    <div>
                      <span className="head-pros">Each capsule contains:</span>
                      <div className="head-di2">
                        <div className="head-di1">
                          <span>Methandienone 10 mg</span>
                        </div>
                        <div className="head-di1">
                          <span>1 Bottle of 100 Capsules </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="head-pro3">
                    <div>
                      <span className="head-pros">Stanozolol</span>
                    </div>
                    <div className="pro-imggd">
                      <img
                        src={require("../../assets/TABLETNEW/STANA.jpg")}
                        alt=""
                      />
                    </div>
                    <div>
                      <span className="head-pros">Each capsule contains:</span>
                      <div className="head-di2">
                        <div className="head-di1">
                          <span>Stanozolol 10 mg</span>
                        </div>
                        <div className="head-di1">
                          <span>1 Bottle of 100 Capsules </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
          <ScrollToTop />
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default Products2;
